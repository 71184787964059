import * as React from 'react';
import { navigate, Link } from "gatsby"
import { 
	Box,
	Button,
	Container,
	Grid,
    Skeleton,
	Typography,
} from '@mui/material';
import PapInput from './pap-input.js';
import LogoDowin from "images/logodowin.png";
import LogoDowinWhite from "images/logodowinWhite.png";
import HandBlue from "images/handBlue.png";

const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '56px',
	marginTop: '0px',
    paddingLeft: '16px',
    marginBottom: '16px',
}
const imageContainer = {
	height: '36.7px',
}
const logoContainer = {
    height: '60px',
}
const headerMargin = {
	marginBottom: '20px',
	marginLeft: '16px',
	display: 'inline-block',
	verticalAlign: 'middle',
}
const informationText = {
    fontFamily: 'Quicksand',
    color: '#767676',
	fontSize: '20px',
	lineHeight: '25px',
	fontWeight: '700',
}
const informationLink = {
    fontFamily: 'Quicksand',
    color: '#3239E5',
	fontSize: '20px',
	lineHeight: '25px',
	fontWeight: '700',
}
const sloganWhite = {
	fontFamily: 'Quicksand',
    color: '#FFFFFF',
	fontSize: '36px',
	lineHeight: '45px',
	alignItems: 'center'
}
const subtitle = {
    fontFamily: 'Quicksand',
	fontSize: '36px',
	lineHeight: '45px',
}
const stepsTitle = {
	fontFamily: 'Quicksand',
	fontSize: '48px',
	lineHeight: '60px',
	fontWeight: '700',
}
const stepsText = {
	fontFamily: 'Quicksand',
	fontSize: '20px',
	lineHeight: '25px',
	fontWeight: '400',
}
const stepsTextBold = {
	fontFamily: 'Quicksand',
	fontSize: '20px',
	lineHeight: '25px',
	fontWeight: '700',
}
const stepsTextIcon = {
	fontFamily: 'Quicksand',
	fontSize: '56px',
	lineHeight: '70px',
	fontWeight: '700',
}
const stepsContainer = {
    paddingTop: '74px',
    paddingBottom: '74px',
    paddingLeft: '180px',
    paddingRight: '180px',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const paddingLeft110 = {
	paddingLeft: '110px',
}
const paddingRight110 = {
	paddingRight: '110px',
}
const blackButton = {
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '32px',
	fontWeight: "700",
	backgroundColor: '#000000',
	width: '100%',
	borderRadius: '50px',
	paddingLeft: '55px',
	paddingRight: '55px',
	paddingTop: '21px',
	paddingBottom: '21px',
    marginTop: '27px',
}
const lightBlueButton = {
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '32px',
	fontWeight: "700",
	backgroundColor: '#2C90CB',
	color: '#FFFFFF',
	width: '100%',
	borderRadius: '50px',
	paddingLeft: '55px',
	paddingRight: '55px',
	paddingTop: '21px',
	paddingBottom: '21px',
}
const lightBlueTextButton = {
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '30px',
	fontWeight: '500',
	color: '#2C90CB',
}
const lightBoxText = {
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '32px',
}
const formContainer = {
	width: '100%',
	marginTop: '32px',
}
const inputStyle = {
	width: '100%',
	minHeight: '72px',
	backgroundColor: '#FFFFFF',
	borderRadius: '12px',
	borderWidth: '0px',
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '24px',
    marginTop: '32px',
}
const inputLabelStyle = {
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '24px',
	paddingTop: '5px',
	borderRadius: '5px',
	color: '#979797',
    marginTop: '32px',
}
const top32 = {
	paddingLeft: '0px',
	paddingRight: '0px',
	marginTop: '32px',
}
const topCenter24 = {
	paddingLeft: '0px',
	paddingRight: '0px',
	marginTop: '24px',
    textAlign: 'center'
}
const topBotCenter2436 = {
	paddingLeft: '0px',
	paddingRight: '0px',
	marginTop: '24px',
    paddingBottom: '36px',
    textAlign: 'center'
}
const heavyBlue = {
    backgroundColor: '#2C90CB',
}
const lightBlue = {
    backgroundColor: '#C6DCE8',
}
const lightGray = {
    backgroundColor: '#F1F1F1',
}
const white = {
    backgroundColor: '#FFFFFF',
}
const loginBox = {
    backgroundColor: '#C6DCE8',
    width: '60%',
    borderRadius: '16px',
}
const imgContainer = {
	width: '244px',
	margin: '0 auto',
}


export default function PapSolicitantsDesktop(props) {
    return (
        <Box style={pageStyles}>
                <Box style={heavyBlue}>
                    <Grid container spacing={2} style={topBar}>
                        <Grid item xs={12}>
                            <Link to="/">
                                <img
                                    alt="dowin Logo"
                                    style={imageContainer}
                                    src={LogoDowinWhite}
                                />
                            </Link>
                            <Box style={headerMargin}>
                                <Typography fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"}> Organizaciones y solicitantes </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    {props.values.loading.user ?
                        <Grid style={verticalPadding40} justifyContent={'center'} container spacing={2}>
                            <Skeleton variant="text" animation="wave" width={'75%'} height={50} />
                            <Skeleton variant="text" animation="wave" width={'75%'} height={50} />
                            <Skeleton variant="text" animation="wave" width={'75%'} height={50} />
                            <br/>
                            <br/>
                            <Skeleton variant="text" animation="wave" width={'30%'} height={70} />
                        </Grid>
                    :
                        <>
                        {props.values.isLogged ? 
                            <Grid style={verticalPadding40} justifyContent={'center'} textAlign={"center"} container spacing={2}>
                                <Grid item xs={10}>
                                        <Typography style={sloganWhite} display={"inline"}>Somos la plataforma de Premium&Premium que te ayuda a conseguir los recursos necesarios para tus </Typography>
                                        <Typography style={sloganWhite} display={"inline"} fontWeight="700">PROYECTOS SOLIDARIOS </Typography>
                                        <Typography style={sloganWhite} display={"inline"}>a través de campañas de donación e incentivar a los donantes con sorteos o regalos solidarios.</Typography>
                                        <Container justifyContent={'center'}>
                                            <Button style={blackButton} onClick={() => navigate("/dashboard")} variant="contained">Ir a mi área privada</Button>
                                        </Container>
                                </Grid>
                            </Grid>
                            :
                            <Grid style={verticalPadding40} justifyContent={'flex-start'} container spacing={2}>
                                <Grid item style={paddingLeft110} xs={6}>
                                    <Container style={loginBox}>
                                        <PapInput
                                            label="Email" 
                                            value={props.values.email}
                                            id="inputEmail"
                                            isPassword={false}
                                            onChange={props.handleChange('email')}
                                            inputStyle={inputStyle}
                                            inputLabelStyle={inputLabelStyle}
                                        />
                                        <PapInput
                                            label="Contraseña" 
                                            value={props.values.password}
                                            id="inputPassword"
                                            isPassword={true}
                                            onChange={props.handleChange('password')}
                                            inputStyle={inputStyle}
                                            inputLabelStyle={inputLabelStyle}
                                        />
                                        <Container style={top32}>
                                            <Button style={lightBlueButton} onClick={props.performLogin} variant="contained">Iniciar sesión</Button>
                                        </Container>
                                        <Container style={topCenter24}>
                                            <Button onClick={props.handleClickForgottenPass} style={lightBlueTextButton}>¿Has olvidado tu contraseña?</Button>
                                        </Container>
                                        <Container style={topCenter24}>
                                            <Typography style={lightBoxText}> ¿Aún no tienes cuenta? </Typography>
                                        </Container>
                                        <Container style={topBotCenter2436}>
                                            <Button onClick={() => navigate("/register")} style={lightBlueTextButton}>Inscríbete como solicitante</Button>
                                        </Container>
                                    </Container>
                                </Grid>
                                <Grid item style={paddingRight110} xs={6}>
                                        <Typography style={sloganWhite} display={"inline"}>Somos la plataforma de Premium&Premium que te ayuda a conseguir los recursos necesarios para tus </Typography>
                                        <Typography style={sloganWhite} display={"inline"} fontWeight="700">PROYECTOS SOLIDARIOS </Typography>
                                        <Typography style={sloganWhite} display={"inline"}>a través de campañas de donación e incentivar a los donantes con sorteos o regalos solidarios.</Typography>
                                        <Button style={blackButton} onClick={() => navigate("/register")} variant="contained">Inscríbete como solicitante</Button>
                                </Grid>
                            </Grid>
                        }
                        </>
                    }
                </Box>
				<Box style={lightBlue}>
                    <Grid style={stepsContainer} container spacing={2}>
                        <Grid item xs={12} style={{paddingBottom: '90px'}}>
                            <Typography variant="h1" style={stepsTitle}>¿Cómo solicitar una donación?</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography style={stepsTextIcon}>📝</Typography>
                            <Typography style={stepsTextBold}>1 Inscríbete en esta web como solicitante.</Typography>
                            <Typography style={stepsText}>Completa tus datos y adjunta la información solicitada.</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography style={stepsTextIcon}>📋</Typography>
                            <Typography style={stepsTextBold}>2 Validaremos los datos de tu organización o tus datos personales.</Typography>
                            <Typography style={stepsText}>Te avisaremos cuando hayamos verificado tus credenciales en breve.</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography style={stepsTextIcon}>✅</Typography>
                            <Typography style={stepsTextBold}>3 Podrás crear una campaña para recaudar fondos.</Typography>
                            <Typography style={stepsText}>Cuando inicies sesión con tus credenciales podrás comenzar.</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={lightGray}>
                    <Grid style={stepsContainer} container spacing={4}>
                        <Grid item xs={12} style={{paddingBottom: '90px'}}>
                            <Typography variant="h1" style={stepsTitle}>¿Quién puede solicitar fondos mediante una campaña de donaciones?</Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography style={stepsTextIcon}>🤝</Typography>
                            <Typography style={stepsTextBold}>ORGANIZACIONES </Typography>
                            <Typography style={stepsText}>Solicita fondos mediante una campaña de donaciones para llevar a cabo los PROYECTOS SOLIDARIOS realizados por tu organización.</Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography style={stepsTextIcon}>👩</Typography>
                            <Typography style={stepsTextBold}>SOLICITANTES INDIVIDUALES</Typography>
                            <Typography style={stepsText}>Cualquier persona podrá solicitar fondos mediante una campaña de donaciones para vuestra organización favorita o para causas sociales o catastrofes naturales.</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={white}>
                    <Grid style={verticalPadding40} container spacing={2}>
                        <Grid item style={paddingLeft110} xs={6}>
                            <Typography variant="h1" style={stepsTitle}>¿Qué tipo de campañas se pueden realizar en dowin?</Typography>
                            <Box textAlign={"center"} display={"flex"} pt={"83px"}>
                                <img
                                    alt="hand holding a heart"
                                    style={imgContainer}
                                    src={HandBlue}
                                />
                            </Box>
                        </Grid>
                        <Grid item style={paddingRight110} xs={6}>
                            <Box style={{paddingTop: '16px'}}>
                                <Typography style={informationText}>🏢 ¿Eres una ONG, Fundación u otra entidad sin ánimo de lucro que necesita recaudar fondos para sus PROYECTOS SOLIDARIOS?</Typography>
                                <Link to="/" style={informationLink}>Más información</Link>
                            </Box>
                            <Box style={{paddingTop: '24px'}}>
                                <Typography style={informationText}>👩‍ ¿Eres un solicitante que quiere recaudar fondos para tu Entidad sin ánimo de lucro favorita o para un PROYECTO SOLIDARIO en concreto?</Typography>
                                <Link to="/" style={informationLink}>Más información</Link>
                            </Box>
                            <Box style={{paddingTop: '24px'}}>
                                <Typography style={informationText}>⚽ ¿Eres una entidad deportiva que necesita recaudar fond️os para ofrecer becas deportivas o niños con menos recursos económicos? </Typography>
                                <Link to="/" style={informationLink}>Más información</Link>
                            </Box>
                            <Box style={{paddingTop: '24px'}}>
                                <Typography style={informationText}>🌪 ¿Quieres solictar fondos para ayudar a las ONGs y otras entidades que están ayudando en una catastrofe natural o social en concreto?</Typography>
                                <Link to="/" style={informationLink}>Más información</Link>
                            </Box>
                            <Box style={{paddingTop: '24px'}}>
                                <Typography style={informationText}>🏫 ¿Eres una entidad educativa que quiere financiar sus viajes culturales u obtener fondos para material para familiar necesitadas?</Typography>
                                <Link to="/" style={informationLink}>Más información</Link>
                            </Box>
                            <Box style={{paddingTop: '24px'}}>
                                <Typography style={informationText}>🏀️ ¿Eres un deportista de élite que necesitas ayuda para financiar los viajes para representar a tu país?</Typography>
                                <Link to="/" style={informationLink}>Más información</Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={lightBlue}>
                        <Grid style={stepsContainer} container spacing={2}>
                            <Grid item xs={12} style={{paddingBottom: '42px'}}>
                                <Typography variant="h1" style={stepsTitle}>¿Cómo incentivar a los donantes?</Typography>
                            </Grid>
                            <Grid item xs={12} style={{paddingBottom: '42px'}}>
                                <Typography variant="h2" style={subtitle}>Si eres un solicitante individual, no te preocupes, Premium & Premium se encarga de todo.</Typography>
                                <Typography variant="h2" style={subtitle}>Si eres una organización tienes las siguientes opciones: </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} pr={"16px"}>
                                <Typography style={stepsTextIcon}>🎟</Typography>
                                <Typography style={stepsTextBold}>REALIZA UN SORTEO ENTRE TUS DONANTES:</Typography>
                                <br/>
                                <Typography style={informationText}>1 Solicita el permiso ante la Dirección General de Ordenación del Juego (DGOJ).</Typography>
                                <a  style={informationLink} href='https://www.ordenacionjuego.es/es/licencias-de-juego'>Obten información aquí</a>
                                <br/>
                                <br/>
                                <Typography style={informationText}>2 Paga la tasa.</Typography>
                                <br/>
                                <Typography style={informationText}>3 Espera a que la DGOJ te autorice a celebrar el sorteo.</Typography>
                                <br/>
                                <Typography style={informationText}>4 Ya puedes organizar el sorteo e incentivar a tus donantes.</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} pr={"16px"}>
                                <Typography style={stepsTextIcon}>🎁</Typography>
                                <Typography style={stepsTextBold}>ORGANIZA LA ENTREGA DE UN REGALO A TODOS TUS DONANTES:</Typography>
                                <br/>
                                <Typography style={informationText}>1 Elige un regalo para premiar a todos tus donantes.</Typography>
                                <br/>
                                <Typography style={informationText}>2 Este regalo puede ser algo que ofrezca la organización o proporcione un patrocinador.</Typography>
                                <br/>
                                <Typography style={informationText}>3 También se puede condicionar el regalo a que el premio de la Lotería Nacional coincida con un número previamente comprado por la organización.</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} pr={"16px"}>
                                <img
                                    alt="dowin Logo"
                                    style={logoContainer}
                                    src={LogoDowin}
                                />
                                <Typography style={stepsTextBold}>DEJA QUE NOSOTROS ORGANICEMOS UN REGALO PARA TUS DONANTES:</Typography>
                                <br/>
                                <Typography style={informationText}>1 En Premium&Premium haremos todo el proceso para incentivar a tus donantes.</Typography>
                                <br/>
                                <Typography style={informationText}>2 Elegiremos el premio que le entregaremos a los donantes.</Typography>
                                <br/>
                                <Typography style={informationText}>3 Buscará un patrocinador si lo consideramos adecuado.</Typography>
                                <br/>
                                <Typography style={informationText}>4 Nos encargaremos de hacerles llegar el regalo en caso de que sean agraciados con el premio.</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
    )
}